<template>
  <div id="main" class="main theme-mini" :class="{ mobile: isMobileOnly }">
    <div id="nav">
      <router-link to="/home" tag="div" id="logo">
        <img src="../assets/et.png" alt="" />
      </router-link>
      <div id="right-nav">
        <!-- MOBILE PUSH MENU -->
        <span class="mobile-toggle" v-if="isMobileOnly" @click="mobileNav = true">
          <el-avatar
            size="small"
            shape="square"
            :style="{ background: avatarBackgroundColor, color: avatarTextColor }"
          >
            {{ initials }}
          </el-avatar>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-drawer
          custom-class="main-push-nav"
          size="300px"
          :title="userName"
          :visible.sync="mobileNav"
          :with-header="false"
        >
          <div v-bar class="drawer-wrapper">
            <div>
              <el-dropdown-item disabled divided> Account(s) </el-dropdown-item>
              <el-dropdown-item
                @click.native="setClientAccount({ item, accountId: item.account.id })"
                v-for="item in avaiableAccounts"
                :key="item.account.id + 'nav'"
                :disabled="item.account.id === account.id"
              >
                <div class="dd-account-name">
                  {{ item.account.name }}
                  <el-tag
                    v-show="item.account.id === account.id"
                    size="mini"
                    class="muted"
                    type="info"
                    >active</el-tag
                  >
                </div>
                <small class="dd-account-client">{{ item.client.name }}</small>
              </el-dropdown-item>
              <el-dropdown-item icon="bi bly-user" @click.native="profile" divided>
                Edit profile
              </el-dropdown-item>
              <el-dropdown-item icon="bi bly-logout" @click.native="logout">
                Logout
              </el-dropdown-item>
            </div>
          </div>
        </el-drawer>
        <!-- DESKTOP MENU -->
        <el-popover placement="bottom" trigger="manual" v-model="showUserMenu">
          <!-- ACCOUNT LIST DESKTOP -->
          <div class="flex items-center justify-between">
            <div>
              <div class="font-bold text-black" @click="profile">{{ userName }}</div>
              <div
                class="nav-profile-account my-0 mt-1 text-xs text-blue-500 cursor-pointer"
                @click="openTermsOfService"
              >
                Terms of Service
              </div>
            </div>
            <div class="user-actions">
              <el-tooltip content="User settings">
                <i class="bi bly-edit icon-btn" @click="profile"></i>
              </el-tooltip>
              <el-tooltip content="Logout">
                <i class="bi bly-logout icon-btn" @click="logout"></i>
              </el-tooltip>
            </div>
          </div>
          <div v-bar class="dd-popup-wrapper">
            <div>
              <div v-for="clientItem in avaiableClients" :key="clientItem.client.id">
                <el-divider content-position="left">{{ clientItem.client.name }}</el-divider>
                <div class="dd-client-account-list">
                  <div
                    class="dd-account-item"
                    :class="{ active: item.account.id === account.id }"
                    @click="
                      setClientAccount({ item, accountId: item.account.id });
                      mobileNav = false;
                    "
                    v-for="item in clientItem.accounts"
                    :key="item.account.id + 'nav'"
                    :disabled="item.account.id === account.id"
                  >
                    <div class="dd-account-name">
                      {{ item.account.name }}
                      <!-- <br>
                      <small>{{item.client.name}}</small>
                      -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END ACCOUNT LIST DESKTOP -->
          <span slot="reference" v-if="!isMobileOnly" class="el-dropdown-link" @click="showUserMenu = !showUserMenu">
            <div class="nav-profile">
              <div class="nav-profile-name">{{ userName }}</div>
              <div class="nav-profile-account">{{ accountName }}</div>
            </div>
            <el-avatar
              size="small"
              shape="square"
              :style="{ background: avatarBackgroundColor, color: avatarTextColor }"
            >
              {{ initials }}
            </el-avatar>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
        </el-popover>
        <div class="separator"></div>
      </div>
    </div>
    <div></div>
    <transition name="slide" mode="out-in" appear>
      <router-view />
    </transition>

    <bly-modal :show.sync="showTos" width="sm:max-w-4xl w-full z-[9999]" :overflow="false" :disableClose="true">
      <h3 slot="header">Terms of use</h3>
      <div class="h-[calc(100vh-60px)] overflow-y-scroll -mx-4 px-8 py-3">
        <bly-tos />
        <div class="flex py-8 justify-between">
          <button class="bg-black btn px-8 py-1" @click="logout">Logout</button>
          <button class="bg-yellow-500 btn px-8 py-1" @click="acceptTos">Accept</button>
        </div>
      </div>
    </bly-modal>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { isMobile, isMobileOnly } from "mobile-device-detect";
import AuthenticationService from "@/services/Authentication";
import ColorGenerator from "@/services/ColorGenerator";

export default {
  data() {
    return {
      interval: null,
      userName: "",
      mobileNav: false,
      isMobile,
      isMobileOnly,
      showUserMenu: false,
      showTos: false,
    };
  },

  computed: {
    ...mapState({
      showDataFromAllAccounts: (state) => state.userAccount.showDataFromAllAccounts,
      isSysAdmin: (state) => state.userAccount.isSysAdmin,
      userSession: (state) => state.userAccount.userSession,
      userGroups: (state) => state.userAccount.userGroups,
      client: (state) => state.userAccount.client,
      listClients: (state) => state.userAccount.listClients,
      clients: (state) => state.userAccount.clients,
      account: (state) => state.userAccount.account,
      userFeatures: (state) => state.userAccount.userFeatures,
    }),
    initials() {
      if (!this.account.name) return "";
      return this.account.name
        .split(" ")
        .map((n) => n[0])
        .join("");
    },
    accountName() {
      return this.account && this.account.name ? this.account.name : "";
    },

    avatarBackgroundColor() {
      return ColorGenerator.generateNewColor(this.accountName + this.account.code);
    },

    avatarTextColor() {
      return "#fff";
    },

    avaiableClients() {
      const clients = [];
      if (!this.listClients.items) return [];
      this.listClients.items.forEach((client) => {
        const accounts = [];
        client.accounts.items.forEach((account) => {
          const isDefault = account.code === client.code;
          accounts.push({
            client,
            account,
            isDefault,
          });
        });
        accounts.sort((a, b) => a.account.name.localeCompare(b.account.name));
        clients.push({
          client,
          accounts,
        });
      });

      return clients;
    },

    avaiableAccounts() {
      const accounts = [];
      if (!this.listClients.items) return [];
      this.listClients.items.forEach((client) => {
        client.accounts.items.forEach((account) => {
          const isDefault = account.code === client.code;
          accounts.push({
            account,
            client,
            isDefault,
          });
        });
      });

      // Sort accounts by account name
      accounts.sort((a, b) => a.account.name.localeCompare(b.account.name));

      return accounts;
    },
  },

  created() {
    const body = document.querySelector("body");
    body.className = this.isMobileOnly ? "mobile" : "desktop";
    this.interval = window.setInterval(this.checkSession, 60000);
    this.checkUser();
    this.fetchUserClientList();
    this.fetchUserAccountList();
    this.listAppSettings();
  },

  mounted() {
    this.checkTos();
  },

  beforeDestroy() {
    window.clearInterval(this.interval);
  },

  methods: {
    ...mapActions({
      fetchUserClientList: "userAccount/fetchClientList",
      fetchUserAccountList: "userAccount/fetchAccountList",
      listAppSettings: "appSettings/listAppSettings",
    }),

    ...mapMutations({
      setCognitoUser: "userAccount/setCognitoUser",
      setPermissions: "userAccount/setPermissions",
      setClient: "userAccount/setClient",
      setClientAccount: "userAccount/setClientAccount",
      setShowDataFromAllAccounts: "userAccount/setShowDataFromAllAccounts",
    }),

    openTermsOfService() {
      this.showTos = true;
      this.showUserMenu = false;
    },
    acceptTos() {
      localStorage.setItem("tosAccepted", new Date());
      this.showTos = false;
    },

    checkTos() {
      const tosAccepted = localStorage.getItem("tosAccepted");
      if (!tosAccepted) {
        this.showTos = true;
        return;
      }

      const tosDate = new Date(tosAccepted);
      const oneYearAgo = new Date();
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

      if (tosDate < oneYearAgo) {
        this.showTos = true;
      }
    },

    async logout() {
      this.mobileNav = false;
      await this.$Amplify.Auth.signOut();
      this.$router.push({ path: "/login" });
    },

    profile() {
      this.mobileNav = false;
      this.$router.push({ path: "/profile" });
    },

    async checkUser() {
      const user = await this.$Amplify.Auth.currentAuthenticatedUser();
      AuthenticationService.setUser(user);
      console.log("user => ", user.signInUserSession.accessToken.payload);
      this.setCognitoUser(user);
      this.setPermissions(AuthenticationService.userPermissions);
      this.userName = AuthenticationService.userEmail;
    },

    async checkSession() {
      await AuthenticationService.checkSession();
    },
  },
};
</script>
<style>
.el-loading-spinner svg {
  margin: 0 auto;
}

.highlight-event {
  background: rgba(255, 159, 49, 0.2) !important;
}

.agenda-filter-popup .group-label {
  margin-top: 10px;
  display: inline-block;
}

.output-status-null {
  display: none;
}

.output-DELETED {
  text-decoration: line-through;
}

.event-group {
  position: relative;
}

.collapse {
  position: absolute;
  left: -24px;
}

.h-5 {
  height: 24px;
}

.w-5 {
  width: 24px;
}

.select-multible {
  position: fixed;
  z-index: 11;
  float: right;
  right: 380px;
  margin-top: 2px;
}

.sticky-date {
  position: fixed;
  z-index: 11;
  background: #000;
  width: 138px;
  /* top: 0; */
  margin-top: -5px;
  padding: 11px;
  color: #cfdfff;
  background: #060809;
  padding: 9px 17px 9px 37px;
  display: inline-block;
  opacity: 1;
  margin-left: -19px;
  font-size: 16px;
}

#agenda-view {
  padding: 20px;
  margin-top: 36px;
}
#agenda-view .wrapper {
  height: calc(100vh - 146px);
}
#agenda-view i.bi.bly-filter.icon-btn.active {
  color: #cfdfff;
}
#agenda-view i.bi.bly-filter.icon-btn.active:after {
  content: " ";
  display: block;
  width: 9px;
  height: 9px;
  background: red;
  border-radius: 5px;
  position: absolute;
  top: 5px;
  right: 4px;
  border: 2px solid #343c49;
}
#agenda-view .tx-report-booking-details {
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 4px;
}
#agenda-view .tx-report-booking-details .content {
  color: #000;
}
#agenda-view .tx-report-booking-details .label {
  font-weight: 600;
  font-size: 11px;
  margin-bottom: 2px;
  text-transform: uppercase;
  opacity: 0.6;
  margin-top: 16px;
}
#agenda-view .agenda-header-columns {
  display: grid;
  outline: 2px solid #13191d;
  grid-gap: 8px;
}
#agenda-view .agenda-header {
  color: #cfdfff;
  display: grid;
  padding: 12px 16px;
  background: #343c49;
  font-size: 12px;
  font-weight: bold;
  /* border-radius: 3px; */
  position: fixed;
  top: 97px;
  left: 20px;
  right: 379px;
  z-index: 99;
  border-top: 5px solid #13191d;
}
#agenda-view .agenda-header .agenda-header-columns {
  display: grid;
}
#agenda-view .agenda-header .agenda-header-settings {
  float: right;
  position: absolute;
  right: 0;
}
#agenda-view .agenda-header .agenda-header-filter {
  position: absolute;
  right: 40px;
}
#agenda-view .quill-editor,
#agenda-view .quill-code {
  height: 20rem;
}
#agenda-view .agenda-day-group {
  margin-bottom: 16px;
  font-weight: 600;
}
#agenda-view .agenda-day-group-name {
  margin-bottom: 4px;
  display: grid;
  grid-template-columns: 80px 70px 70px 100px 100px 130px 240px auto;
  opacity: 0.7;
  font-size: 11px;
  align-items: center;
}
#agenda-view .agenda-day-group-name div {
  color: #cdd6ee;
  padding-left: 16px;
  padding-top: 4px;
  background: #000;
}
#agenda-view .agenda-day-group-name-name {
  grid-column: span 12;
  background: transparent !important;
}
#agenda-view .agenda-day-group-name-name span {
  color: #cfdfff;
  background: #000;
  padding: 4px 16px;
  display: inline-block;
  opacity: 1;
  margin-left: -16px;
  font-size: 16px;
}
#agenda-view .agenda-day-group .agenda-day-event {
  padding: 4px 16px;
  background: #232b33;
  border-radius: 3px;
  margin-bottom: 6px;
  align-items: start;
  color: #cfdfff;
  position: relative;
}
#agenda-view .agenda-day-group .agenda-day-event .agenda-label {
  display: none;
}
#agenda-view .agenda-day-group .agenda-day-event-actions {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #1e242c;
  height: 40px;
  background: linear-gradient(90deg, rgba(30, 36, 44, 0) 0%, rgba(30, 36, 44, 0.91) 40%);
  display: none;
}
#agenda-view .agenda-day-group .agenda-day-event:hover,
#agenda-view .agenda-day-group .agenda-day-event.active {
  outline: 2px solid #ff9f31;
  color: #cfdfff;
  padding: 4px 16px;
}
#agenda-view .agenda-day-group .agenda-day-event:hover .agenda-day-event-actions,
#agenda-view .agenda-day-group .agenda-day-event.active .agenda-day-event-actions {
  display: block;
}
#agenda-view .agenda-day-group .agenda-day-event-details {
  grid-template-columns: 80px 70px 70px 100px 100px 130px 240px auto;
  display: grid;
  grid-gap: 8px;
}
#agenda-view .agenda-day-group .agenda-day-event-name {
  color: #fff;
  background: rgba(6, 8, 9, 0.18);
  padding: 4px 16px;
  margin: -1px -12px 4px -12px;
  border-radius: 3px;
}
#agenda-view .agenda-day-group .agenda-day-event-name .agenda-day-event-name.LIVE:after {
  position: absolute;
  display: block;
  content: " ";
  left: -6px;
  border: 3px solid #fff;
  right: -6px;
  top: -6px;
  bottom: -6px;
}
#agenda-view .agenda-day-group .agenda-day-event-name span.fc-event-account {
  background: #13191d;
  line-height: 30px;
  margin: -6px -16px -4px -16px;
  padding: 0 10px;
  float: right;
  text-transform: uppercase;
  height: 30px;
  font-size: 12px;
}
#agenda-view .agenda-day-group .agenda-day-event-name span.fc-event-account-client {
  color: #cdd6ee;
  margin: 0 -10px 0px 4px;
  display: inline-block;
  padding: 0px 0px 0px;
}
#agenda-view .agenda-day-group .agenda-day-event-name.LIVE:after {
  content: "";
  position: absolute;
  left: -6px;
  top: -6px;
  bottom: -6px;
  right: -6px;
  border: 3px solid #fff;
}
#agenda-view .agenda-day-group .agenda-day-event-name-description {
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
}
#agenda-view .agenda-day-group .agenda-day-event-startTime small {
  opacity: 0.7;
  font-size: 11px;
  display: block;
}
#agenda-view .agenda-day-group .agenda-day-event-endTime small {
  opacity: 0.7;
  font-size: 11px;
  display: block;
}
#agenda-view .agenda-day-group .agenda-day-event-kickOff small {
  opacity: 0.7;
  font-size: 11px;
  display: block;
}
#agenda-view .agenda-day-group .agenda-day-event-Audio {
  white-space: pre-line;
  margin-top: -16px;
  font-size: 13px;
}
#agenda-view .agenda-day-group .agenda-day-event-Input {
  word-break: break-all;
}
#agenda-view .agenda-day-group .agenda-day-event-Outputs {
  word-break: break-all;
}
#agenda-view .agenda-day-group .agenda-day-event-Outputs .other div {
  margin-bottom: 2px;
}
#agenda-view .agenda-day-group .agenda-day-event-Outputs .show-ma {
  font-size: 80%;
  opacity: 0.8;
  margin-bottom: 4px !important;
}
#agenda-view .agenda-day-group .agenda-day-event-Outputs .show-ma.null {
  display: none;
}
@media screen and (max-width: 1200px) {
  #agenda-view .agenda-day-group .agenda-day-event {
    display: grid;
    padding: 4px 16px;
    grid-template-columns: 70px 70px 70px 100px 120px 150px 100px 150px 150px;
    grid-template-rows: 1fr 1fr;
  }
  #agenda-view .agenda-day-group .agenda-day-event-name {
    grid-column: span 9;
  }
}

.mobile #agenda-view {
  padding: 20px;
  margin-top: 10px;
}
.mobile #agenda-view .wrapper {
  height: calc(100vh - 146px);
  width: 100%;
}
.mobile #agenda-view .agenda-header {
  right: 20px;
}
.mobile #agenda-view .agenda-day-group .agenda-day-event {
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
}
.mobile #agenda-view .agenda-day-group .agenda-day-event-name {
  grid-column: span 9;
}
.mobile #agenda-view .agenda-day-group .agenda-day-event-details {
  grid-template-columns: 1fr;
}
.mobile #agenda-view .agenda-day-group .agenda-day-event-details > div {
  display: grid;
  grid-template-columns: 120px 1fr;
}
.mobile #agenda-view .agenda-day-group .agenda-header-columns {
  grid-template-columns: 1fr;
}
.mobile #agenda-view .agenda-day-group .agenda-label {
  display: inline-block;
  min-width: 120px;
}
.mobile #agenda-view .agenda-day-group-name {
  grid-template-columns: 1fr auto;
}
.mobile #agenda-view .agenda-header-columns {
  display: none;
  grid-template-columns: 1fr;
}

.theme-mini #agenda-view .agenda-day-group {
  font-weight: 400;
}
.theme-mini #agenda-view .commentary-language {
  color: #fff;
  background: #232b33;
  height: 20px;
  position: relative;
  top: 0px;
  padding-top: 7px;
  padding-left: 10px;
  left: -10px;
  padding-right: 11px;
  padding-bottom: 10px;
}
.theme-mini #agenda-view .agenda-day-event-name {
  background: transparent !important;
}
.theme-mini #agenda-view .agenda-day-event-name .agenda-day-event-name-description {
  color: #66718d !important;
}
.theme-mini #agenda-view .agenda-day-event-name span.fc-event-account {
  background: #13191d;
  line-height: 30px;
  margin: 0;
  padding: 0 10px;
  float: right;
  text-transform: uppercase;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 0 0 6px;
}
.theme-mini #agenda-view .agenda-day-event-name:before {
  display: block;
  position: absolute;
  content: " ";
  background: red;
  top: 4px;
  bottom: 4px;
  width: 5px;
  left: 4px;
}
.theme-mini #agenda-view .agenda-day-event-details {
  padding-left: 4px;
}
.theme-mini .event-preview-drawer #el-drawer__title .client-name {
  font-weight: 400;
}

span.moschecked {
  background: #00ff80;
  font-weight: bold;
  color: #fff;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  padding-top: 4px;
  font-size: 11px;
  margin-right: 4px;
}

.group-events {
  margin-left: 20px;
}
.group-events.collapsed {
  height: 0;
  overflow: hidden;
}

.remap-tag {
  @apply bg-yellow-500;
  color: #ffffff;
  padding: 0 6px;
  border-radius: 3px;
  position: relative;
  top: -2px;
  font-size: 11px;
  height: 18px;
  display: inline-block;
  margin-left: 6px;
  line-height: 19px;
}
</style>

